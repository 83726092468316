.sticky-menu, .menu-button, .sticky-menu-mobile {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    padding: 10px 20px;
    background-color: white;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
}

.menu-button {
    height: 48px;
    width: 48px;
    padding: 0px;
}

.sticky-menu-mobile {
    margin-top: 32px;
    background-color: white;
    box-shadow: 0px 10px 5px rgba(0,0,0,0.2) !important;
}

.sticky-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.sticky-menu ul li {
    margin-bottom: 10px;
    text-align: left;
}

.sticky-menu ul li a {
    text-decoration: none;
    color: black;
    font-size: 16px;
}

.active {
    color: #4a6741 !important;
    padding: 0px 10px;
    font-weight: bold;
}