.App {
  color: black;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh; /* Viewport height */
}

#root { 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgb(242, 242, 242);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1 {
  color: black !important;
  font-size: 100px;
  font-weight: 500 !important;
  font-family: helvetica-w01-roman, helvetica-w02-roman, helvetica-lt-w10-roman, sans-serif;
  margin-bottom: 4px !important;
}

h2 {
  color : black !important;
  font-size: 24px;
  margin:0px !important;
}

h3 {
  margin:0px !important;
}

p {
  font-size: 16px;
  font-family: helvetica-w01-roman, helvetica-w02-roman, helvetica-lt-w10-roman, sans-serif;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.banner-gradient {
  z-index: 0;
  height: 300px;
  top: 0;
  width: 100%;
  position: absolute;
  background-image: radial-gradient(circle at 3.67513% 6.9401%, rgb(77, 96, 1) 0%, 11.55%, rgba(77, 96, 1, 0) 35%), radial-gradient(circle at 92.9671% 9.55404%, rgb(250, 170, 99) 0%, 17.5%, rgba(250, 170, 99, 0) 35%), radial-gradient(circle at 95.2067% 90.4818%, rgb(201, 198, 255) 0%, 34.1%, rgba(201, 198, 255, 0) 55%), radial-gradient(circle at 4.31315% 93.3464%, rgb(151, 180, 157) 0%, 29.9%, rgba(151, 180, 157, 0) 65%), radial-gradient(circle at 46.556% 11.5039%, rgba(214, 222, 72, 0.99) 0%, 25%, rgba(214, 222, 72, 0) 50%), radial-gradient(circle at 51.1214% 89.987%, rgb(147, 195, 255) 0%, 42%, rgba(147, 195, 255, 0) 70%), radial-gradient(circle at 48.9014% 49.5215%, rgb(255, 255, 255) 0%, 100%, rgba(255, 255, 255, 0) 100%);
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

.ant-form-item-label > label {
  color:white !important;
  font-size: 18px !important;
}

.separator {
  height: 4px; 
  background-color: #4a6741; 
  width: 100%;
  margin: 20px 0;
}

.separator-thin {
  height: 1px;
  background-color: #333;
  width: 100%;
  margin: 20px 0;
}

.separator-nav {
  height: 1px; 
  background-color: #333; 
  width: 100%;
  margin: 2px 0;
}

.section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  width: 80%;
  margin: auto;
}

.section-home {
  display: flex;
}

.hero-section{
  width: 50%;
  display: flex;
  flex-direction: column;
}

.section.even {
  flex-direction: row;
}

.section.odd {
  flex-direction: row-reverse;
}

.text-content, .image-content {
  flex: 1;
  padding: 10px;
}

.image-content{
  align-items: center;
  max-width: 50%;
}

.image-content img {
  max-height: 300px;
  display:block;
  margin:auto;
  max-width: 100%;
}

.image-content img.odd {
  padding-right: 50px;
}
.image-content img.even {
  padding-left: 50px;
}

.publication-content {
  margin: 36px 0px;
  padding-right: 15px;
}

.read-button, .statement-button {
  background-color: black;
  border: 1px solid black;
  color: white;
  padding: 8px 10px;
  width: 142px;
  text-align: center;
  display: block;
  font-size: 16px;
  letter-spacing: 0.2em;
}

.statement-button {
  width: 250px;
}

.read-button:hover, .statement-button:hover{
  background-color: white;
  border: 1px solid black;
  color: black;
}

.home-footer{
  text-align: center;
  width: 65%;
  margin: auto;
  margin-top: 100px;
  margin-bottom : 50px;
}

.main-content{
  margin-top: 100px;
  position: relative;
  max-width: 1200px;
  z-index: 2;
  background-color: rgb(242, 242, 242);
}

.publication-header, .resource-header {
  padding: 72px;
}

.publication-header h1, .resource-header h1{
  font-size: 56px;
  padding: 0px 0px;
}

.center2{
  width:auto;
}

.text-center{
  text-align: center;
}

.home-sub-header{
  font-family: helvetica-w01-roman, helvetica-w02-roman, helvetica-lt-w10-roman, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.menu-button {
  z-index: 99;
  display: none;
}

.popup-menu {
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.popup-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.popup-menu li a {
  display: block;
  padding: 15px 20px;
  text-decoration: none;
  color: #333;
}

.about-me{
  display: block;
  margin-bottom: 50px;
}

.resource-image{
  filter: grayscale(100%);
  max-width: 50%;
}

.resource-image img{
  max-width: 80%;
  height: auto;
}

.hero-image{
  width: 100%;
  margin-right: 15px;
}

.portrait-image{
  width: 75%;
}

.isbn{
  margin-bottom: 16px !important;
}

.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
}

.image-container img {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

@media (max-width: 1200px) {
  .menu-button {
      display: block;
  }

  .center {
    width: 95%
  }
}

@media (max-width: 1000px) {
  h1 {
    font-size: 75px;
  }

  .publication-header, .resource-header{
    padding:16px
  }

  .section {
    flex-direction: column-reverse !important;
    width:95%;
    margin: 0px;
  }

  .section-home{
    flex-direction: column !important;
  }

  .hero-section{
    width: 100%;
    flex-direction: column;
  }

  .hero-section img{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .hero-section h1{
    text-align: center;
  }

  .portrait-image{
    width: 65% !important;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .about-me, .publication-content, .publication-header p{
    font-size: 1.2em;
  }

  .about-me{
    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    align-self: center;
  }

  .text-content {
    margin-top: 0px;
    padding: 0px;
  }

  .text-content h2, .text-content h3 {
    text-align: center;
  }

  .text-content h2{
    font-size: 1.5em;
  }

  .image-content {
    max-height: 500px;
  }

  .image-content img {
    max-width: 100%;
  }

  .image-content img.odd {
    padding-right: 0px;
  }
  .image-content img.even {
    padding-left: 0px;
  }

  .container {
      flex-direction: column;
      align-items: center;
  }
  .image-container {
      width: 50%;
      margin-bottom: 35px;
  }
}

@media (max-width: 750px) {
  h1 {
    font-size: 64px;
  }
}